import React, { useEffect, useState } from "react";
import moment from "moment";
import { Paper } from "@material-ui/core";
import Slider from "react-slick";
import { useDispatch, useStore } from "react-redux";
import Grid from "@material-ui/core/Grid";

import plus from "assets/img/plus.png";
import download from "assets/img/download.png";
import Chatbot from 'views/Components/chatbot/Chatbot';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import SearchHeader from "./Components/SearchHeader";
import CustomTable from "../../components/CustomTable/CustomTable";
import GridItem from "components/Grid/GridItem.js";
import { SHOW_LOADER, HIDE_LOADER } from "action_creators/actioncreator";
import CreateTemplateModal from "./Components/CreateTemplateModal";
import ConfirmationModal from "./Components/ConfirmationModal";
import {
  SET_TEMPLATES,
  SET_WIZARDS,
  SHOW_NOTIFICATION,
} from "../../action_creators/actioncreator";
import {
  getAllTemplates,
  getAllWizards,
  getDesignerObjects,
  removeTemplate,
} from "../../apis/APIServices";
import "./Desgin.scss";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Zoom from "@material-ui/core/Zoom/Zoom";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import DesignCenterUpgrade from "../Components/DesignCenterUpgrade";
import { Alert } from "@material-ui/lab";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ImportDesignModal from "./Components/ImportDesignModal";
import { SDCloudBackendAPIWithToken } from "../../apis/backendAPI";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import deleteicon from 'assets/img/deleteicon.png';
import { Padding } from "@mui/icons-material";
var columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "User",
    accessor: "username",
  },
  {
    Header: "Date created",
    accessor: "created_at",
  },
  {
    Header: "Last used",
    accessor: "last_used",
  },
  {
    Header: "",
    accessor: "actions",
  },
];
if(localStorage.getItem("profileData") && JSON.parse(localStorage.getItem("profileData")).privilege !== 'admin'){
  for(let col=0 ;col < columns.length ;col++){
    if(columns[col].accessor == "username"){
      columns.splice(col,1);
    }
  }
}

const errPayload = {
  message: "Something went wrong.",
  color: "danger",
};

const useStyles = makeStyles((theme) => ({
  bgColor: {
    background: theme.palette.secondary.main,
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
  },
}));

const FeatureBox = ({
  action,
  className,
  active,
  icon,
  label,
  iconClass,
  font,
}) => (
  <Paper
    onClick={action || null}
    className={`feature-box ${active && "active"} ${className || ""}`}
    elevation={0}
  >
    <div>
      <span className="box-icon">
        <img src={icon} alt="icon" className={iconClass || ""} />
      </span>
    </div>
    <div className={`box-label ${font}`}>{label}</div>
  </Paper>
);

export default function Design() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useStore();
  const reduxDesignStore = store.getState().design;
  const [rows, setRows] = useState([]);
  const [allWizards, setWizards] = useState({});
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [isNewTemplateVisible, setNewTemplateVisible] = useState(false);
  const [isImportModal, setIsImportModal] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [actionWizard, setActionWizard] = useState("");
  const [showNotification, setShowNotification] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [rowstemp, setrowstemp] = useState([]);
  const [searchValue, setsearchvalue] = useState("");
  const [recordCount, setrecordCount] = useState(10);
  const [pageNo, setpageNo] = useState(1);
  const [listvalue, setlistvalue] = useState("");
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  useEffect(() => {
    // getTemplates();
    getWizards();
    getDesignObjects();
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Design Center":"Design Center"));
  }, []);

  useEffect(() => {
    const { templates, wizards } = reduxDesignStore;
    // setSearchedTemplate(templates);
    setSearchedTemplate(pageNo,recordCount,searchValue);
    setWizards(wizards);
  }, [reduxDesignStore,pageNo,recordCount,searchValue]);

  const onRowSelect = (rows) => {
    setSelectedTemplates(rows);
  };

  const getDesignObjects = async () => {
    const designObject = localStorage.getItem("design_objects");
    if (!designObject) {
      const response = await getDesignerObjects();
      if (response.success) {
        localStorage.setItem("design_objects", JSON.stringify(response.data));
      }
    }
  };

  // const getTemplates = async () => {
  //   dispatch(SHOW_LOADER());
  //   const res = await getAllTemplates();
  //   // if (!res.success) {
  //   //   dispatch(SHOW_NOTIFICATION({ payload: res.msg || errPayload }));
  //   //   dispatch(SET_TEMPLATES(res.data));
  //   //   return true;
  //   // }
  //   dispatch(SET_TEMPLATES(res.data));
  //   // dispatch(HIDE_LOADER());
  // };
  
  const getWizards = async () => {
    const res = await getAllWizards();
    if (!res.success) {
      dispatch(SHOW_NOTIFICATION({ payload: res.msg || errPayload }));
      return true;
    }
    const list_length = Object.keys(res.data).length;
    setlistvalue(list_length);
    dispatch(SET_WIZARDS(res.data));
    dispatch(HIDE_LOADER());
  };
  let slidesToShow = 4
  if(listvalue < 4){
    slidesToShow = listvalue
  }
  const settings = {
    dots: true,
    infinite: true,
    draggable: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <ArrowForwardIosOutlinedIcon/>,
    prevArrow: <ArrowBackIosOutlinedIcon/>
  };

  const handleDelete = () => {
    setConfirmModal(false);
    if (!selectedTemplates.length) return true;

    dispatch(SHOW_LOADER());
    Promise.all(
      selectedTemplates.map((templ) => removeTemplate(templ.original.uuid))
    )
      .then((values) => {
        setSearchedTemplate(pageNo,recordCount,searchValue);
        dispatch(HIDE_LOADER());
        for (let t = 0; t < values.length; t++) {
          if (values[t].success == true) {
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage("Successfully deleted the template");
          } else {
            setShowNotification(true);
            setNotificationType("error");
            setNotificationMessage(values[t].msg);
          }
        }
      })
      .catch((e) => {
        dispatch(SHOW_NOTIFICATION({ payload: errPayload }));
        dispatch(HIDE_LOADER());
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to delete the template");
      });
  };

  const handleConfirmation = () => {
    if (!selectedTemplates.length) return true;
    setConfirmModal(true);
  };

  const handleTemplateView = async (name, uuid, isMultiVM,isLiveEdit, cluster,flavor, cloudNative) => {
    if (
      localStorage.getItem("BASE_URL") == null ||
      localStorage.getItem("BASE_URL") == undefined
    ) {
      const BASE_URL = window.REACT_APP_API_BASE_URL;
      localStorage.setItem("BASE_URL", BASE_URL);
    }
    if (
      localStorage.getItem("design_objects") == null ||
      localStorage.getItem("design_objects") == undefined
    ) {
      var resp = await getDesignerObjects();
      if (resp.success) {
        localStorage.setItem("design_objects", JSON.stringify(resp.data));
      }
    }
    var route;
    if (
      window.location.protocol !== "https:" &&
      window.location.hostname !== "localhost"
    ) {
      if(cluster == 'cloudNative' || cloudNative){
        route = `https:${window.location.hostname}` + "/design_center/cloud_native.html?";
      }else{
      route = `https:${window.location.hostname}` + "/design_center/?";
      }
    } else {
      if(cluster == 'cloudNative' || cloudNative){
        route = "/design_center/cloud_native.html?";
      }else{
      route = "/design_center/?";
      }
    }
    if (uuid) {
      route = `${route}uuid=${uuid}`;
    } else if (actionWizard) {
      if (isMultiVM) {
        route = `${route}new_template=${name}&title=${actionWizard}&multi_vm=${isMultiVM}`;
      } else {
        route = `${route}new_template=${name}&title=${actionWizard}&liveEdit=${isLiveEdit}`;
      }
    } else {
      if (isMultiVM) {
        route = `${route}new_template=${name}&multi_vm=${isMultiVM}`;
      } else {
        route = `${route}new_template=${name}&liveEdit=${isLiveEdit}`;
      }
    }
    window.location.href = route;
    localStorage.removeItem("CLUSTER_FLAVOR");
    if(flavor){
      localStorage.setItem("CLUSTER_FLAVOR", flavor);
    }
  };
  const setSearchedTemplate = async (page_no,record_count,search_value) => {
    let templateList = []
    SDCloudBackendAPIWithToken()
      .get(
        `/designer/template_list?search=${search_value ??
          searchValue}&page_no=${
          page_no ? page_no : pageNo
        }&record_count=${record_count? record_count:recordCount}`
      )
      .then((resp) => resp.data)
      .then((resp) => {
        // setpageNo(resp.pagination.current_page)
        resp.templates.forEach((item) => {
          templateList.push({
            created_at: item.created_at
              ? moment(item.created_at).format("YYYY-MM-DD | HH:mm:ss A")
              : null,
            status: item.status,
            uuid:item.uuid,
            username: item.username,
            name: item.name?.split("|")[0],
            last_used: item.last_used
              ? moment(item.last_used).format("YYYY-MM-DD | HH:mm:ss A")
              : null,
            actions: (
              <div onClick={() => handleTemplateView(null, item.uuid, null, null, null,null, item.is_cloud_native)}>
                <Tooltip
                  id="tooltip-top"
                  enterDelay={200}
                  leaveDelay={200}
                  TransitionComponent={Zoom}
                  title="Go to template"
                  placement="bottom-start"
                >
                  <ArrowRightIcon style={{ cursor: "pointer" }} />
                </Tooltip>
              </div>
            ),
          });
        });
        setRows(templateList);
        setrowstemp(resp);
      })
  };
  // const setSearchedTemplates = (templates) => {
  //   const updatedList = [];
  //   for (const i in templates) {
  //     // eslint-disable-line
  //     updatedList.push({
  //       ...templates[i],
  //       created_at: templates[i].created_at
  //         ? moment(templates[i].created_at).format("YYYY-MM-DD | HH:mm:ss A")
  //         : null,
  //       status: templates[i].status,
  //       name: templates[i].name?.split("|")[0],
  //       last_used: templates[i].last_used
  //         ? moment(templates[i].last_used).format("YYYY-MM-DD | HH:mm:ss A")
  //         : null,
  //       actions: (
  //         <div onClick={() => handleTemplateView(null, templates[i].uuid, null, null, null,null, templates[i].is_cloud_native)}>
  //           <Tooltip
  //             id="tooltip-top"
  //             enterDelay={200}
  //             leaveDelay={200}
  //             TransitionComponent={Zoom}
  //             title="Go to template"
  //             placement="bottom-start"
  //           >
  //             <ArrowRightIcon style={{ cursor: "pointer" }} />
  //           </Tooltip>
  //         </div>
  //       ),
  //     });
  //   }
  //   setRows(updatedList);
  // };
  const handlePagination = (page_no,record_count) => {
    setrecordCount(record_count)
    setpageNo(page_no);
  };
  const handleChange = (event) => {
    setsearchvalue(event.target.value);
  };

  return (
    <div className="design-page">
      <ConfirmationModal
        visible={isConfirmModal}
        onClose={() => setConfirmModal(false)}
        onConfirm={handleDelete}
        selectedTemplate={selectedTemplates.length}
      />
      {isNewTemplateVisible && (
        <CreateTemplateModal
          visible={isNewTemplateVisible}
          onClose={() => {
            setActionWizard("");
            setNewTemplateVisible(false);
          }}
          actionWizard={actionWizard}
          onCreateTemplate={handleTemplateView}
          is_multi_vm={JSON.parse(localStorage.getItem("profileData")).multi_vm}
          cloud_native_enabled ={JSON.parse(localStorage.getItem("profileData")).cloud_native_enabled}
        />
      )}
      {!reduxDesignStore.templates ? (
        <div className="design-card design-card2">
          <DesignCenterUpgrade />
        </div>
      ) : (
        <GridContainer>
          <GridItem md={12}>
            {showNotification ? (
              <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
              >
                {notificationMessage}
              </Alert>
            ) : null}
          </GridItem>
          <Grid container spacing={3} className="bg-gray pt-4 d-card ">
            <Grid item xs={12} sm={2} className=" ">
              <div class="design-create-card">
                <p class={`create-card-title ${classes.font}`}>
                  Create New Design
                </p>
                <button
                  className={`${classes.bgColor +
                    " " +
                    classes.font} button new-design-button`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setNewTemplateVisible(true)}
                >
                  {" "}
                  <img src={plus} className="" /> <br /> New Design{" "}
                </button>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} className="">
              <div class="design-create-card">
                <p class={`create-card-title ${classes.font}`}>
                  Create New Design from Wizard
                </p>
                {/* <button className="button wizard-design-button"> <img src={standart} className="" /> <br/> Standart  SDWAN <br/>Topology </button>
               <button className="button wizard-design-button"> <img src={hup} className="" /> <br/> Hub & spoke <br/> . </button>
               <button className="button wizard-design-button"> <img src={dual} className="" /> <br/> Dual DC with <br/>DCI link </button>
               <button className="button wizard-design-button"> <img src={cloud} className="" /> <br/> Controllers in <br/>the cloud </button> 
               <img src={rightarrow} className="mt-1" /> */}

                <div className="content">
                  <Slider {...settings}>
                    {Object.keys(allWizards || {}).map((key, index) => {
                      const wizard = allWizards[key];
                      return (
                        <FeatureBox
                          key={index}
                          icon={wizard.image}
                          label={wizard.title}
                          action={() => {
                            setActionWizard(wizard.title);
                            setNewTemplateVisible(true);
                          }}
                          iconClass="size35"
                          font={classes.font}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={2} className=" ">
              <div class="design-create-card">
                <p
                  class={`create-card-title ${classes.font}`}
                  style={{ color: "lightgray" }}
                >
                  Import Design
                </p>
                <button
                  className={`button download-design-button ${classes.font}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsImportModal(true)}
                >
                  {" "}
                  <img src={download} className="" /> <br /> Import{" "}
                </button>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="bg-gray pt-3 ">
            <Grid item xs={11} sm={11} className=" ">
              <p class={`mydesign-title ${classes.font}`}>My Designs</p>
            </Grid>
          </Grid>
          {/* <GridItem className="new-template" md={4}>
            <div className="label">Create new template</div>
            <div className="content">
              <FeatureBox
                className="mr-45"
                icon={plusIcon}
                active
                label="New graph (Manually)"
                action={() => setNewTemplateVisible(true)}
              />             
            </div>
          </GridItem> */}
          {/* <GridItem className="wizard" md={8}>
            <div className="label">From wizard</div>
            <div className="content">
              <Slider {...settings}>
                {Object.keys(allWizards || {}).map((key, index) => {
                  const wizard = allWizards[key];
                  return (
                    <FeatureBox
                      key={index}
                      icon={wizard.image}
                      label={wizard.title}
                      action={() => {
                        setActionWizard(wizard.title);
                        setNewTemplateVisible(true);
                      }}
                      iconClass="size35"
                    />
                  );
                })}
              </Slider>
            </div>
          </GridItem> */}
          <GridItem className="list-view" md={12}>
          <div className="search-header">
            <Grid
              container
              className={classes.headerContainer}
              alignItems="center"
              justify="space-between"
              wrap="wrap"
              style={{margin: "23px 0"}}
              
            >
              <Grid className="first-col" item xs={12} sm={12} md={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  className="gray-btn trash"
                  onClick={handleConfirmation}
                >
                  <span>
                    <img alt="trash" src={deleteicon} />
                  </span>
                </Button>
                <TextField
                  type="search"
                  variant="filled"
                  className="search-input"
                  fullWidth
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  value={searchValue}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </div>
            {/* <SearchHeader
              handleDelete={handleConfirmation}
              setSearchList={setSearchedTemplate}
              // templates={reduxDesignStore.templates || []}
              value={searchValue}
              onChange={(event) => {
                handleChange(event);
              }}
            /> */}
            <CustomTable
              data={rows}
              columns={columns}
              onRowSelect={onRowSelect}
              handleTemplateView={handleTemplateView}
              isRowSelection
              count={rowstemp?.pagination?.total_pages}
              page_no={rowstemp?.pagination?.current_page}
              setTemplatesList={handlePagination}
            />
          </GridItem>
        </GridContainer>
      )}
      {isImportModal && (
        <ImportDesignModal
          onCreateTemplate={handleTemplateView}
          visible={isImportModal}
          onClose={() => {
            setActionWizard("");
            setIsImportModal(false);
          }}
        />
      )}
       < Chatbot/>
    </div>
  );
}
